import React, { PureComponent } from 'react'
import { Layout, SEO, Wrapper } from '@renderbus/common/components'
import { graphql } from 'gatsby'
import { ContentWrapper, Content, Section, PageWrapper } from './start-new-year.atom'
import { StartNewYearBanner } from '../molecules/banner'
import { ActivityRules } from './xgt-xmas.html'
import Title1 from '../images/xmas/title-1.png'
import Title2 from '../images/xmas/title-2.png'
import Title3 from '../images/xmas/title-3.png'

import Welfare1 from '../images/start-new-year/welfare-1.png'
import Welfare2 from '../images/start-new-year/welfare-2.png'

import WelfareFlow1 from '../images/start-new-year/welfare-flow-1.png'
import WelfareFlow2 from '../images/start-new-year/welfare-flow-2.png'

const CardItem = [
  {
    price: 200,
    discount: '送220元',
    discount2: '到账420元',
  },
  {
    price: 500,
    discount: '送550元',
    discount2: '到账1050元',
  },
  {
    price: 800,
    discount: '送900元',
    discount2: '到账1700元',
  },
  {
    price: 2000,
    discount: '送2500元',
    discount2: '到账4500元',
  },
]

class StartNewYear extends PureComponent {
  onClick = () => {
    window.open('https://account.renderbus.com/center/user/topUp', '_blank')
  }
  render() {
    const {
      location,
      pageContext: { topThreeShare },
    } = this.props
    return (
      <Layout location={location} topThreeShare={topThreeShare}>
        <SEO
          title='瑞云效果图新春开年充值活动 - 瑞云渲染'
          keywords='效果图充值活动,效果图渲染活动，瑞云充值活动'
          description='新年新气象,开年开财运,Renderbus瑞云渲染效果图新春充值活动福利来袭,充值最高送2500元,一年仅一次！'
        />
        <StartNewYearBanner inPage />
        <PageWrapper>
          <Wrapper>
            <ContentWrapper>
              <Section>
                <img className='title-img t1' src={Title1} alt='充值优惠' />
                <div className='xmas-content c1'>
                  {CardItem.map(item => (
                    <div className='gold-card' key={item.price}>
                      <div className='xmas-price'>{item.price}</div>
                      <div className='xmas-discount'>
                        <span>{item.discount}</span>
                        <span className='discount-dot'>，</span>
                        <span>{item.discount2}</span>
                      </div>
                      <div className='xmas-button' onClick={this.onClick}>
                        立即充值
                      </div>
                    </div>
                  ))}
                </div>
              </Section>
              <Section>
                <img className='title-img t2' src={Title2} alt='参与方式' />
                <div className='xmas-content c2'>
                  <div className='card-2'>
                    <img src={Welfare1} className='card-2-welfare' alt='福利一' />
                    <div className='welfare-title'>福利一</div>
                    <img className='welfare-flow' src={WelfareFlow1} alt='流程一' />
                    <div className='welfare-text-flow welfare-text-flow-1'>
                      <span>登录账号</span>
                      <span className='text-flow-center'>充值</span>
                      <span>自动赠送渲染券</span>
                    </div>
                  </div>
                  <div className='card-2'>
                    <img src={Welfare2} className='card-2-welfare' alt='福利二' />
                    <div className='welfare-title'>福利二</div>
                    <img className='welfare-flow' src={WelfareFlow2} alt='流程二' />
                    <div className='welfare-text-flow welfare-text-flow-2'>
                      <span>登录账号</span>
                      <span className='text-flow-center'>完成实名认证</span>
                      <span>系统自动赠送一个月会员</span>
                    </div>
                  </div>
                </div>
              </Section>
              <Section>
                <img className='title-img t3' src={Title3} alt='活动规则' />
                <Content>
                  {ActivityRules.map((rule, index) => (
                    <p key={index}>{rule}</p>
                  ))}
                </Content>
              </Section>
            </ContentWrapper>
          </Wrapper>
        </PageWrapper>
      </Layout>
    )
  }
}
export const query = graphql`
  query {
    banner: file(relativePath: { regex: "/start-new-year/banner.png/" }) {
      ...fluidImage
    }
    bannerMb: file(relativePath: { regex: "/index/start-new-year-banner-mb.png/" }) {
      ...fluidImage
    }
  }
`
export default StartNewYear
